<template>
  <div class="content">
    <div class="card">
      <div class="card-header d-flex align-items-center py-0">
        <h4 class="text-secondary py-3 mb-0">Meus Agendamentos</h4>
        <div class="ms-auto">
          <router-link to="/" class="btn btn-outline-dark" title="Sair">
            Sair
          </router-link>
        </div>
      </div>
      <LoadingData v-if="loadData" />
      <SchedulesTable v-if="schedules.length > 0" :schedules="schedules" />
      <div v-if="!loadData && schedules.length == 0" class="mt-2">
        <h5 class="text-secondary text-center">
          Nenhum agendamento encontrado para o CPF informado
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/globals/LoadingData.vue'
import SchedulesTable from '@/components/collaborators/preview/schedules/list/SchedulesTable.vue'

export default {
  name: 'ListSchedule',

  components: {
    LoadingData,
    SchedulesTable,
  },

  data() {
    return {
      cpf: this.$route.params.cpf,
      schedules: [],
      loadData: false,
    }
  },

  methods: {
    async getSchedules() {
      try {
        this.loadData = true
        this.schedules = await this.$store.dispatch(
          'getSchedulesByCollaborator',
          {
            cpf: this.cpf,
          },
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getSchedules()
  },
}
</script>
