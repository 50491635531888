<template>
  <div class="table-responsive">
    <table
      class="table table-sm table-bordered table-striped"
      :id="dataTable.id"
    >
      <thead>
        <tr>
          <th>Funcionário</th>
          <th class="text-center">Tipo de Exame</th>
          <th class="text-center">Cidade</th>
          <th class="text-center">Unidade</th>
          <th class="text-center">Data</th>
          <th class="text-center">Hora</th>
          <th class="text-center">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(schedule, index) in schedules" :key="index">
          <td>{{ schedule.collaboratorName }}</td>
          <td class="text-center">{{ schedule.typeOfExamName }}</td>
          <td class="text-center">{{ schedule.cityName }}</td>
          <td class="text-center">{{ schedule.unityName }}</td>
          <td class="text-center">
            {{
              schedule.date
                ? dateTimeSqlToDateBR(schedule.date)
                : 'Não Selecionada'
            }}
          </td>
          <td class="text-center">
            {{ schedule.time ? schedule.time : 'Não Selecionada' }}
          </td>
          <td
            v-html="statusLabel(schedule.calendarStatus, schedule.status)"
            class="text-center"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import '@/utils/basicDataTableExtend'
import { statusLabel } from '@/utils/strings'
import { dateTimeSqlToDateBR } from '@/utils/date'

export default {
  name: 'SchedulesTable',

  props: {
    schedules: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataTable: {
        id: 'dataTableListSchedules',
        order: [[4, 'desc']],
      },
      statusLabel,
      dateTimeSqlToDateBR,
    }
  },

  methods: {
    createDataTable() {
      window.$(`#${this.dataTable.id}`).DataTable({
        order: this.dataTable.order,
      })
    },
  },

  mounted() {
    this.createDataTable()
  },
}
</script>

<style scoped>
td {
  padding: 0.5rem !important;
}
</style>
